import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "WQHD Cameras and Motion Detection Areas",
  "path": "/Frequently_Asked_Question/WQHD_Motion_Detection_Areas/",
  "dateChanged": "2022-09-28",
  "author": "Mike Polinowski",
  "excerpt": "How to adjust the Motion Detection Areas of my WQHD Camera for an optimal Result?",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='WQHD Cameras and Motion Detection Areas' dateChanged='2022-09-28' author='Mike Polinowski' tag='INSTAR IP Camera' description='How to adjust the Motion Detection Areas of my WQHD Camera for an optimal Result?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/WQHD_Motion_Detection_Areas/' locationFR='/fr/Frequently_Asked_Question/WQHD_Motion_Detection_Areas/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    
    <h1 {...{
      "id": "wqhd-cameras-and-motion-detection-areas",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#wqhd-cameras-and-motion-detection-areas",
        "aria-label": "wqhd cameras and motion detection areas permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`WQHD Cameras and Motion Detection Areas`}</h1>
    {/* TOC */}
    {/* /TOC */}
    <p><strong parentName="p">{`Q`}</strong>{`: How to adjust the Motion Detection Areas of my WQHD Camera for an optimal Result?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: For our older camera models we often recommended using a large `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Areas/#potential-errors"
      }}>{`Motion Detection Area`}</a>{` to minimize false alerts. For our new camera series we improved the motion detection to be more reliable by surpressing background "noise" - see `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Alarm/Areas/#background-compensation"
      }}>{`Background Compensation`}</a>{`. This allows for a much more precise placement of the available detection areas.`}</p>
    <p>{`Rules of Thumb when placing the detection areas:`}</p>
    <ol>
      <li parentName="ol">{`Place each area `}<strong parentName="li">{`directly in the path`}</strong>{` that persons, animals or cars will take when crossing through the image.`}</li>
      <li parentName="ol">{`The detection areas do not only react when a person or a car is moving in front of the camera. But also `}<strong parentName="li">{`sudden light changes`}</strong>{` - moving shadows, car headlights, etc - can trigger an alert. Try to place detection areas so that not affected by sudden changes in light conditions.`}</li>
      <li parentName="ol">{`Adjust the `}<strong parentName="li">{`sensitivity`}</strong>{` of the detection according to the `}<strong parentName="li">{`percentage of pixels`}</strong>{` inside the area you expect to change when you want an alarm to be triggered:`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`1 % sensitivity`}</code>{`: `}<strong parentName="li">{`99%`}</strong>{` of the pixels inside the detection area have to change to trigger an alarm.`}</li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`50 % sensitivity`}</code>{`: `}<strong parentName="li">{`50%`}</strong>{` of the pixels inside the detection area have to change to trigger an alarm.`}</li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`75 % sensitivity`}</code>{`: `}<strong parentName="li">{`25%`}</strong>{` of the pixels inside the detection area have to change to trigger an alarm.`}</li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`99 % sensitivity`}</code>{`: `}<strong parentName="li">{`1%`}</strong>{` of the pixels inside the detection area have to change to trigger an alarm.`}</li>
        </ul>
      </li>
      <li parentName="ol">{`The `}<strong parentName="li">{`larger the detection area`}</strong>{` the more absolute pixels have to change to meet this condition. For example, an area covering the entire camera image will only trigger when something huge moves in front of the camera - or something small comes close enough to cover a large portion of the screen:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b0fa0260505b8a052b2f631dcb35bbe4/32ac3/WQHD_Motion_Detection_Areas_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACNElEQVQoz33Pz2/SYBzH8f41Ju4waPv8KmzLGLQTKIWnLU9/MKBEAxIJ63CCyw4mOxnPXjQxzsQDYSZutE856FX/A+PVP8WUOWMys+R9eL6HVz55BLX/qvpiWZotirOL4myxN00rTP88/p6F6WL3eL579FF7NleP5/XTT5XwjWA2/Fl79NQfhu4gdAdHtwqd/sR7PHH6s8H09OSl83Bi9ULn0cRsD4Ugn1tJGS5muJRNQHYFsisoXpdAkYNsLGYiKXN5/963TuvHu7eBy2hDN02zTptCsLPDIYwAjBHmGHNCOCGJksYVwjGJIFoCeClJSX4relDuMmY7rOm2dMO8wRDGCHGMOMGJglOcW3uC1xhcyfJSFBditmPbNmt6fouaa5xAlGKMOL6WOMkrq61comBOcIxusCxfANA2TWpZjuNS+i9GiBOUkBu8nef/wx3bYq7ntrq6bgjd7e1IFJeyHEGUjhPEFZyQlHEFxxhHEF7JcoolaSGKbcuiluW5Lm00hN7e3hdVjTBeAhCjtV//PN1cz6YYgEtJ4rnclaYFjDmex/xO3TCEJ77/68P513J5ubnJAUwASINwBWEC0zOW5RiAzxsb34Pg5/n7nt9qUOr5Lds5EKheOxmOxrpxWFKP9vdvF2paqGmHpeKUsdPhwLNNs2nX/W7FaAi1sjoOar0DWms2jTtirGZSnbXNYBTY3phadlUXrErh9WHleVDWq7qh67U7qlbqB6Pq4KxvOGcljRXV3yCeIJfzek4PAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b0fa0260505b8a052b2f631dcb35bbe4/e4706/WQHD_Motion_Detection_Areas_01.avif 230w", "/en/static/b0fa0260505b8a052b2f631dcb35bbe4/d1af7/WQHD_Motion_Detection_Areas_01.avif 460w", "/en/static/b0fa0260505b8a052b2f631dcb35bbe4/7f308/WQHD_Motion_Detection_Areas_01.avif 920w", "/en/static/b0fa0260505b8a052b2f631dcb35bbe4/92269/WQHD_Motion_Detection_Areas_01.avif 1249w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b0fa0260505b8a052b2f631dcb35bbe4/a0b58/WQHD_Motion_Detection_Areas_01.webp 230w", "/en/static/b0fa0260505b8a052b2f631dcb35bbe4/bc10c/WQHD_Motion_Detection_Areas_01.webp 460w", "/en/static/b0fa0260505b8a052b2f631dcb35bbe4/966d8/WQHD_Motion_Detection_Areas_01.webp 920w", "/en/static/b0fa0260505b8a052b2f631dcb35bbe4/c7643/WQHD_Motion_Detection_Areas_01.webp 1249w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b0fa0260505b8a052b2f631dcb35bbe4/81c8e/WQHD_Motion_Detection_Areas_01.png 230w", "/en/static/b0fa0260505b8a052b2f631dcb35bbe4/08a84/WQHD_Motion_Detection_Areas_01.png 460w", "/en/static/b0fa0260505b8a052b2f631dcb35bbe4/c0255/WQHD_Motion_Detection_Areas_01.png 920w", "/en/static/b0fa0260505b8a052b2f631dcb35bbe4/32ac3/WQHD_Motion_Detection_Areas_01.png 1249w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b0fa0260505b8a052b2f631dcb35bbe4/c0255/WQHD_Motion_Detection_Areas_01.png",
              "alt": "WQHD Cameras and Motion Detection Areas",
              "title": "WQHD Cameras and Motion Detection Areas",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In the following image we see a person walking in front of the camera at a distance of approximately 5m. The detected motion is highlighted in white. And even though the sensitivity is set to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`90%`}</code>{` and no time we see a change of greater than `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`10%`}</code>{` of the pixels - so we never get an alarm:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/50891edbfa8b720ef3a12f4c236ab256/d9199/WQHD_Motion_Detection_screenshot_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAAXElEQVQoz2NgwAYYwYCBbECOZiYmJgYGBnNz84kTJ5Kp2cTEJD09nZWVlRwHy8jI6OnpGRkZwY0j1p/q6up+fn7h4eGzZs0i2ecQDVpaWtHR0WS6nNKookg/bQEAGvkLsC5gr0sAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/50891edbfa8b720ef3a12f4c236ab256/e4706/WQHD_Motion_Detection_screenshot_01.avif 230w", "/en/static/50891edbfa8b720ef3a12f4c236ab256/d1af7/WQHD_Motion_Detection_screenshot_01.avif 460w", "/en/static/50891edbfa8b720ef3a12f4c236ab256/7f308/WQHD_Motion_Detection_screenshot_01.avif 920w", "/en/static/50891edbfa8b720ef3a12f4c236ab256/542bc/WQHD_Motion_Detection_screenshot_01.avif 960w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/50891edbfa8b720ef3a12f4c236ab256/a0b58/WQHD_Motion_Detection_screenshot_01.webp 230w", "/en/static/50891edbfa8b720ef3a12f4c236ab256/bc10c/WQHD_Motion_Detection_screenshot_01.webp 460w", "/en/static/50891edbfa8b720ef3a12f4c236ab256/966d8/WQHD_Motion_Detection_screenshot_01.webp 920w", "/en/static/50891edbfa8b720ef3a12f4c236ab256/e46b2/WQHD_Motion_Detection_screenshot_01.webp 960w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/50891edbfa8b720ef3a12f4c236ab256/81c8e/WQHD_Motion_Detection_screenshot_01.png 230w", "/en/static/50891edbfa8b720ef3a12f4c236ab256/08a84/WQHD_Motion_Detection_screenshot_01.png 460w", "/en/static/50891edbfa8b720ef3a12f4c236ab256/c0255/WQHD_Motion_Detection_screenshot_01.png 920w", "/en/static/50891edbfa8b720ef3a12f4c236ab256/d9199/WQHD_Motion_Detection_screenshot_01.png 960w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/50891edbfa8b720ef3a12f4c236ab256/c0255/WQHD_Motion_Detection_screenshot_01.png",
              "alt": "WQHD Cameras and Motion Detection Areas",
              "title": "WQHD Cameras and Motion Detection Areas",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`But now that we know the size of the expected object as well as it's expected position we can adjust the detection area accordingly. I also lower the sensitivity to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`75%`}</code>{` so that only `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`25%`}</code>{` of the pixel need to change to trigger an alarm:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3117423ac8508f96f6bb839dbaf3f7b3/fe8a7/WQHD_Motion_Detection_Areas_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.08695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACmElEQVQoz32Ry08acRDHN8aK7G8f7O5v3zysEo0iCMJqggqyCAIrr32xu+Cjh1p67EnhUGvUlLS9NvbSS/+CXmzSkv5vDVgvPfST72VmMpnvzCC90U939Nu6G5u3Y+P210Q3T3oMp3nzbty4eqgMvjeufjSvHpz3Y+PyG1IoqNnsTja7k0ptZjIZRckoW4qyPVFayWym04mNDWVrO6NsmZZ9ORh2Oo5pWqZlH9WbyOrKim92liIIKkDSDMXyNC9CXmZ5GUKeoWgKxzAURREEyedy19fvHKejt1um7VZaHrK+FsP9KAchz3NLQWkxIgefy6HFYGgxJEckXhIYCAmCBBgGAMYwUNd1x3Vt76xcqSHrsRiBAg6yHM99TCbexNfYBSkSDYWiQXlBEmQBQkiSJIZh8/PzBEHoum6Zlud5zUYDicdiJMB4juUE9msm/TYVZyLCQjQYXgpNm3nITpoBAH6/n6Kodrtt27bleNpRHYnH1gMAYyFkOfY+szlMJmBYCE9sB+XI38kEQQAAUBTQNG1Mbbu902q1iqwurzybmaEDAZpmvqSSw3iMlrlQRJLDohgUeYGnaRrHcQCAz+fDcbzVahmG4TodrVZD9nZ3l6NREsMDZCAhimuyxAm8KPGiLAgiD1mWoqjHhSVJSqaSlmW5nmd3X2iahgyHgw+jkX9uDvOjKIbjJMnQNGQYCBmaoianApjfP3lVTdM+39973a7ebndd1zRNRLc6DcPdyxUKhUJRVQ9UtaiqxSmqqqoFdVLY38/n8/VGo+vatt5smWbdO61oNaTfa968bvbPbKvj/BfXMk3juG+eX5zbvYFuOTUNeWUffOoXj9sHxVLl8LD8L6Vy6Yly6aBqvyyfXJwcti72C+293B9MpLC4Nd1HQgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3117423ac8508f96f6bb839dbaf3f7b3/e4706/WQHD_Motion_Detection_Areas_02.avif 230w", "/en/static/3117423ac8508f96f6bb839dbaf3f7b3/d1af7/WQHD_Motion_Detection_Areas_02.avif 460w", "/en/static/3117423ac8508f96f6bb839dbaf3f7b3/7f308/WQHD_Motion_Detection_Areas_02.avif 920w", "/en/static/3117423ac8508f96f6bb839dbaf3f7b3/fd543/WQHD_Motion_Detection_Areas_02.avif 1223w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3117423ac8508f96f6bb839dbaf3f7b3/a0b58/WQHD_Motion_Detection_Areas_02.webp 230w", "/en/static/3117423ac8508f96f6bb839dbaf3f7b3/bc10c/WQHD_Motion_Detection_Areas_02.webp 460w", "/en/static/3117423ac8508f96f6bb839dbaf3f7b3/966d8/WQHD_Motion_Detection_Areas_02.webp 920w", "/en/static/3117423ac8508f96f6bb839dbaf3f7b3/c77ba/WQHD_Motion_Detection_Areas_02.webp 1223w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3117423ac8508f96f6bb839dbaf3f7b3/81c8e/WQHD_Motion_Detection_Areas_02.png 230w", "/en/static/3117423ac8508f96f6bb839dbaf3f7b3/08a84/WQHD_Motion_Detection_Areas_02.png 460w", "/en/static/3117423ac8508f96f6bb839dbaf3f7b3/c0255/WQHD_Motion_Detection_Areas_02.png 920w", "/en/static/3117423ac8508f96f6bb839dbaf3f7b3/fe8a7/WQHD_Motion_Detection_Areas_02.png 1223w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3117423ac8508f96f6bb839dbaf3f7b3/c0255/WQHD_Motion_Detection_Areas_02.png",
              "alt": "WQHD Cameras and Motion Detection Areas",
              "title": "WQHD Cameras and Motion Detection Areas",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The placement and shape of the detection area now guarantees that a large amount of pixel will detect a change when another person will walk by our camera:`}</p>
    <p><img parentName="p" {...{
        "src": "/en/8081408639174888ae3f05917dc62630/WQHD_Motion_Detection_screenshot_02.gif",
        "alt": "WQHD Cameras and Motion Detection Areas"
      }}></img></p>
    <p>{`And this time the alarm was triggered successfully:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4926cdef221ba7ae050f56723b8b44b2/78612/WQHD_Motion_Detection_Areas_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "21.304347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAAr0lEQVQI12XNvQqDMAAEYK3iIKRF+zPZJRgUQ3ESXEwKGhwc7RuURHDwBZMUjC9WtN38uOngOOuxgRCO47gsi9afeTbznzFmbaSUSmmltFSqf/V5nkfRHUJoZVmGMUYIMcamaRJCDMMgBsEFf+9wzmMUgxPwfT8MQwtv0jQtioIxVpGKPAmha6odSmlwDmzXdlwHgOP/OUmSsiy7rqubumHNL2ynbdvr7eJ4B9dbx1/lrUjTJ6n9ZwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4926cdef221ba7ae050f56723b8b44b2/e4706/WQHD_Motion_Detection_Areas_03.avif 230w", "/en/static/4926cdef221ba7ae050f56723b8b44b2/d1af7/WQHD_Motion_Detection_Areas_03.avif 460w", "/en/static/4926cdef221ba7ae050f56723b8b44b2/7f308/WQHD_Motion_Detection_Areas_03.avif 920w", "/en/static/4926cdef221ba7ae050f56723b8b44b2/51f3d/WQHD_Motion_Detection_Areas_03.avif 1260w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4926cdef221ba7ae050f56723b8b44b2/a0b58/WQHD_Motion_Detection_Areas_03.webp 230w", "/en/static/4926cdef221ba7ae050f56723b8b44b2/bc10c/WQHD_Motion_Detection_Areas_03.webp 460w", "/en/static/4926cdef221ba7ae050f56723b8b44b2/966d8/WQHD_Motion_Detection_Areas_03.webp 920w", "/en/static/4926cdef221ba7ae050f56723b8b44b2/4ef06/WQHD_Motion_Detection_Areas_03.webp 1260w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4926cdef221ba7ae050f56723b8b44b2/81c8e/WQHD_Motion_Detection_Areas_03.png 230w", "/en/static/4926cdef221ba7ae050f56723b8b44b2/08a84/WQHD_Motion_Detection_Areas_03.png 460w", "/en/static/4926cdef221ba7ae050f56723b8b44b2/c0255/WQHD_Motion_Detection_Areas_03.png 920w", "/en/static/4926cdef221ba7ae050f56723b8b44b2/78612/WQHD_Motion_Detection_Areas_03.png 1260w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4926cdef221ba7ae050f56723b8b44b2/c0255/WQHD_Motion_Detection_Areas_03.png",
              "alt": "WQHD Cameras and Motion Detection Areas",
              "title": "WQHD Cameras and Motion Detection Areas",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/36b200a979d58c4262aeed812636ec8b/85053/WQHD_Motion_Detection_Areas_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "65.21739130434783%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAA7rAAAO6wFxzYGVAAADoUlEQVQ4yyVSS08bZxT1z6jUXdVuiJQFJJiWNFVooWDGM998M7ZnDCZQ83AwOBgwNpEwDwO2x5hgYUoQxjyTFOooIAUKgpCESBGWKlVKV5W6qlCXkTqLqqrH36nsLq7u41wdnaN7TS3a+QdH/EKns691fuqlbgmf6Jbwqd4UPtGbxn7SufFjXZw5123xt7oUfa3LsQtdir7Rbdrb8kyceVWKv5yJi79d2tlvJkHgijLlYKPN6PW4EZ2bRDzhRSz6ALFYFKNjERBixy1zLeq+qsedW3fQUFeP6sqb+ML8OagkQZZtEKkEQuU/TTJp/LfTZUHPXY4tLcyw8/wFW89l2C/vf2fvLt+zZHqLefpHWd/QOFPVDlZbWc1uVFxn/DcN7NOPPmZEEJgsS0Uq2UAdriuTQ24u3HMT+HsVls1+j7UfnqInOIL+0VfoC77BUDAOQXYhuX2EvdNfMTk2jdvmGvD13+LLqmqIhEBVnUVZaYVIhCuTYuMK3i6KEV8L29zOYnVvG9OZCPaOz3BweIb08go6vT5E08tIrW7j4PAIO48zcFCCa598Via02+SiKCsQVHdJIVfweWQEBjrY5uMtdIUCqOIqMbuu4cXJPmLJRSTcnchPT+BgZxPPDn7Ek90sdnYeQbbysDQ1weFwFCW7E0RW/yfsKykMDbF0JgO3P4Ta22bE52LYf/4cwckYkuYanF2rwNHKEvaP95HJprGwMIuxgB+qopQJS5aJ5Lgy2eWSZRmh8Wk2O/8QicV1RLQowotTmMok0Trgx1J9I05vVOFJKoXcYQ6ra4uYS0QQDg7DqaoQRbF8FNGmXpnsUnOhr8eJ4Yl5Fo5riD7cwtdeAnOuBjXPbsLcXYe1OgteXq/AbjqFvcMcHq2moGnTGB0cRIvTCYeiFu3Ou6BULBFaCr3dKvwTKRaYmsf8eg6+xBq6ZyIYyW5hZvcCkZCGqO8BohENG083kNlcRjKhITgYKF0YEqVFVXFAUZQrEy8I/wgSLSrtHqO9Z8Dw3A8Y3lDMcLUPGO57YWMosmK09wYNtcNrtLg6jR6Px+j3eQ3FoRqClTPslDeoTS5YVRd4Sv8wdTl5THoEuBUrrDwPgbeCt1pAiBUC3wxrcyMIz0EU+NKfQRAE8KU9gQdt7QZ1B/CdpGKYJ2jjhQ8mf5vl3caIJX+/zXrJ8WKeimJeFMU8IWKelGtazuWekDJWxgU+L3YM5YV+Le+lrZexhsafuyzci/8A+i9KVQdox3kAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/36b200a979d58c4262aeed812636ec8b/e4706/WQHD_Motion_Detection_Areas_04.avif 230w", "/en/static/36b200a979d58c4262aeed812636ec8b/d1af7/WQHD_Motion_Detection_Areas_04.avif 460w", "/en/static/36b200a979d58c4262aeed812636ec8b/7f308/WQHD_Motion_Detection_Areas_04.avif 920w", "/en/static/36b200a979d58c4262aeed812636ec8b/3c69e/WQHD_Motion_Detection_Areas_04.avif 1225w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/36b200a979d58c4262aeed812636ec8b/a0b58/WQHD_Motion_Detection_Areas_04.webp 230w", "/en/static/36b200a979d58c4262aeed812636ec8b/bc10c/WQHD_Motion_Detection_Areas_04.webp 460w", "/en/static/36b200a979d58c4262aeed812636ec8b/966d8/WQHD_Motion_Detection_Areas_04.webp 920w", "/en/static/36b200a979d58c4262aeed812636ec8b/1d10f/WQHD_Motion_Detection_Areas_04.webp 1225w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/36b200a979d58c4262aeed812636ec8b/81c8e/WQHD_Motion_Detection_Areas_04.png 230w", "/en/static/36b200a979d58c4262aeed812636ec8b/08a84/WQHD_Motion_Detection_Areas_04.png 460w", "/en/static/36b200a979d58c4262aeed812636ec8b/c0255/WQHD_Motion_Detection_Areas_04.png 920w", "/en/static/36b200a979d58c4262aeed812636ec8b/85053/WQHD_Motion_Detection_Areas_04.png 1225w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/36b200a979d58c4262aeed812636ec8b/c0255/WQHD_Motion_Detection_Areas_04.png",
              "alt": "WQHD Cameras and Motion Detection Areas",
              "title": "WQHD Cameras and Motion Detection Areas",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      